import '../scss/app.scss';

document.querySelectorAll('.toggle-menu').forEach((toggle) => {
    toggle.addEventListener('click', () => {
        let isActive = toggle.classList.contains('active');

        if (isActive) {
            document.querySelector('.mobile-menu').classList.remove('active');
        } else {
            document.querySelector('.mobile-menu').classList.add('active');
        }
    });
});
